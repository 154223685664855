.boardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.board {
  flex: 0 1 calc(25% - 20px);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: filter 0.3s ease;
}

.board:hover {
  filter: brightness(80%);
}

@media (max-width: 1008px) {
  .board {
      flex: 0 1 100%; /* Hace que cada tablero ocupe el ancho completo del contenedor */
  }
}

.addNewBoard {
  background-color: #e0e0e0;
}

.addBoardText {
  cursor: pointer;
}

.boardTitle {
  padding: 10px;
}

.selectBusinessContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.businessSection {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
}

.businessName {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

  
.titleBot{
  background: linear-gradient(to right, #6E48AA, #00c6ff); /* A linear gradient from left to right */
  border-radius: 30px 30px 0px 0px;
  color: white;
  margin-top: 0px;
  text-align: center;
  padding: 1rem;
  justify-content: center;
  font-size: calc(100vh/50);
  font-weight: 500;
  letter-spacing: 2px;
}

.inputCreateBoard{
  font-size: calc(100vh / 66);
  border-radius: calc(100vh / 50);
  padding: calc(100vh / 97);
  border: 1px solid rgba(247, 0, 255, 0.185);
  background-color: #b4e7ff13;
  width: 85%;
  text-align: center;
  margin-top: calc(100vh / 122);
  box-sizing: border-box;
  font-weight: 100;
}

.inputCreateBoard:focus {
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  background-color: #f4efff;
  outline: none;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.spinner-bubble {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: spinner-bubble-animate 1.5s linear infinite,
             spinner-color-cycle 6s ease-in-out infinite;
}

@keyframes spinner-bubble-animate {
  0% { transform: scale(1) translateX(0); opacity: 0.5; }
  25% { transform: scale(1.1) translateX(-15px); opacity: 1; }
  50% { transform: scale(1.2) translateX(15px); opacity: 1; }
  100% { transform: scale(1) translateX(0); opacity: 0.5; }
}

@keyframes spinner-color-cycle {
  0%, 100% { background-color: #03c5c3; }
  25% { background-color: #4b66ad; }
  50% { background-color: #b24fb1; }
  75% { background-color: #100522; }
}
