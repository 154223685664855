

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #e9e9e9;
  border-radius: 10px;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-item {
  margin-bottom: -1px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
  background-color: #714b79;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.imgFicha {
  width: calc(100vh/5.5);
  height: calc(100vh/5.5);
  border-radius: calc(100vh / 2);
  margin: calc(100vh / 70);
  padding: calc(100vh / 300);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border: 5px solid rgba(255, 255, 255, 0.26);
  box-sizing: border-box;

}

.tableFicha {
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin: auto;
  border-radius: 20px;
  padding: 2px;
  font-size: calc(100vh/60);
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.26);
  box-sizing: border-box;

}

.imgFicha:hover {
  opacity: 0.5;
}

.columnaFicha {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  color: white;
  box-sizing: border-box;
}
