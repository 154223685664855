
@keyframes aparicionConRebote {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.estructuraTicketIA {
  animation-name: aparicionConRebote;
  animation-duration: 0.4s;
  animation-timing-function: ease-out; /* Asegura que la animación se ralentice hacia el final */
  position: relative;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  align-self: center;
  align-items: center;
  text-align: center;
  margin: auto;
  flex-direction: column;
  display: flex;
  border-radius: 15px 15px 15px 15px;
  z-index: 2;
  top: -65px; /* Ajusta la cantidad de superposición */
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  width: 80%; /* El contenido es el 90% del título */
}

.contentWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px; /* Agrega un padding-bottom */
  box-sizing: border-box;
}

.createTicketTitle {
  width: 80%;
  background: linear-gradient(90deg, #6e48aa, #00c6ff);
  color: white;
  font-size: calc(100vh/30); /* Ajusta al tamaño deseado */
  font-weight: 400;
  text-align: center;
  padding: 50px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative; /* Esto es necesario para el z-index */
}

.InputTime {
  padding: 0.4rem;
  font-size: 20px;
  margin: 0.2rem;
  text-align: left;
  position: relative;
  display: block;
  font-size: calc(100vh / 70);
  color: #A07890;
  width: calc(100vh / 4.3);
  height: calc(100vh/60);
  border-radius: calc(100vh/50);
  margin-top: 10px;
  border: 1px solid rgba(247, 0, 255, 0.185);
  background-color: #ffffff28;


.inputTime:focus {
  box-shadow: none;
  outline: none;
}
.inputTime:hover {
  background-color: #6e48aa; /* Color de fondo en estado hover */
  color: #ffffff; /* Cambia el color del texto en estado hover para mejor contraste */
  border-color: #8a2be2; /* Opcional: Cambia el color del borde en estado hover si lo deseas */
}}

.inputCreateTicket {
  font-size: calc(100vh / 66);
  border-radius: calc(100vh / 50);
  padding: calc(100vh / 97);
  border: 1px solid rgba(247, 0, 255, 0.185);
  background-color: #b4e7ff13;
  width: 85%;
  text-align: center;
  margin-top: calc(100vh / 122);
  height: calc(100vh /10);
  box-sizing: border-box;
  font-weight: 100;
}

.inputCreateTicket:focus {
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  background-color: #f4efff;
  outline: none;
}

.iaResult {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
}

.DescriptionMessageTicket {
  position: relative;
  max-width: calc(100vh / 1.2);
  font-size: calc(100vh / 70);
  padding: calc(100vh / 90);
  margin: auto;
  margin-top: 10px;
  align-items: center;
  background-color: #f8f8f8;
  color: #575757;
  border-bottom: #f8f8f8 2px solid;
  justify-content: center;
  border-radius: 15px;
  box-sizing: border-box;
  white-space: pre-wrap; 
}

.DescriptionMessageTicketRed {
  position: relative;
  max-width: calc(100vh / 1.2);
  font-size: calc(100vh / 70);
  padding: calc(100vh / 90);
  margin: auto;
  margin-top: 10px;
  align-items: center;
  background-color: #9f1d4263;
  color: #ffffff;
  border-bottom: #f8f8f8 2px solid;
  justify-content: center;
  display: inline-block; /* Ajuste clave aquí */
  border-radius: 15px;
  box-sizing: border-box;
  white-space: pre-wrap; 
}

.edit-icon {
  display: none;
  margin-left: 5px;
  font-size: 20px;
}

.DescriptionMessageTicket:hover .edit-icon {
  display: inline;
}

.DescriptionMessageTicket:hover {
  background-color: #f8e8fc;
  cursor: pointer;
}

.DescriptionMessageTicketRed:hover .edit-icon {
  display: inline;
}

.DescriptionMessageTicketRed:hover {
  background-color: #f8e8fc;
  color: #122556;
  cursor: pointer;
}

.centerSpinner {
  display: flex;
  width: 100%;
  height: 100%; /* Agregar esta línea para que ocupe todo el espacio vertical disponible */
  justify-content: center;
  align-items: center; /* Esto centrará verticalmente el contenido */
}

.spinner2 {
  width: calc(100vh/50);
  height: calc(100vh/50);
  border-radius: 15px;
  margin-right: 3px;
  background-color: #4b024591;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(10px) }
  50% { -webkit-transform: perspective(10px) rotateY(180deg) }
  100% { -webkit-transform: perspective(10px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.rowButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.fechaVencimiento{
color: #6b6e6e;
padding: 5px;
font-weight: 100;
font-size: calc(100vh/75);
}

